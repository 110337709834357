 <!--
 * =====================================================================
 * 작 업 명  : 리불루이스 점역엔진 테스트
 * 파 일 명  : BrailleLiblouis.vue
 * 변경이력
 * 2023-06-16 / 정태화 / 최초작성
 * 2023-09-04 / 신성진 / grade 추가
 * =====================================================================
 -->
<template>
	<div class="container">
		<h3>점역엔진 테스트</h3><br>
		<div class="form-check form-check-inline">
			<input class="form-check-input" v-model="brailleKind" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="DOT" checked>
			<label class="form-check-label" for="inlineRadio1">Dot</label>
		</div>
		<div class="form-check form-check-inline">
			<input class="form-check-input" v-model="brailleKind" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="LIBLOUIS">
			<label class="form-check-label" for="inlineRadio2">Liblouis</label>
		</div>
		<div class="form-check form-check-inline">
			<input class="form-check-input" v-model="brailleKind" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="MULTILINE">
			<label class="form-check-label" for="inlineRadio3">Multiline</label>
		</div>
		<div class="form-check form-check-inline">
			GRADE
			<select v-model="option" class="form-select" aria-label="Default select example">
				<option value="1">1</option>
				<option value="2">2</option>
			</select>
		</div>
		<div class="form-check form-check-inline">
			<select v-model="language" class="form-select" aria-label="Default select example">
				<option value="english">english</option>
				<option value="korean">korean</option>
				<option value="thai">thai</option>
				<option value="arabic">arabic</option>
				<option value="french">french</option>
				<option value="japanese">japanese</option>
				<option value="german">german</option>
				<option value="russian">russian</option>
				<option value="spanish">spanish</option>
				<option value="vietnamese">vietnamese</option>
				<option value="czech">czech</option>
				<option value="polish">polish</option>
				<option value="norwegian">norwegian</option>
				<option value="kazakh">kazakh</option>
				<option value="portuguese">portuguese</option>
				<option value="danish">danish</option>
				<option value="greek">greek</option>
				<option value="swedish">swedish</option>
				<option value="finnish">finnish</option>
			</select>
		</div>
		<input v-model="inputText"><button @click="translateBraille()">점역</button>
		<br><br>
		<textarea v-model="outputBraille" style="width:800px; height:300px; font-size:50px"></textarea>
	</div>
</template>
<script>

import { Braille } from "@/components/dtms-editor-v1.7/js/Braille.js";
import { $axios } from "dot-framework";

	export default
	{
		data()
		{
			return {
				brailleKind: "DOT",
				language: "english",
				option: 2,
				inputText: "hello",
				outputBraille: ""
			}
		},
		methods:
				{
			// 점역 변환
			async translateBraille()
			{
				let url = "/braille-app/v1/braille/translation-console";
				if (this.brailleKind == "LIBLOUIS") {
					url = "/braille-app/v1/braille/translation-liblouis";
				} else if (this.brailleKind == "MULTILINE") {
					url = "/braille-app/v1/braille/translation-multiline-test";
				}
				const params = {
					"LANGUAGE": this.language,
					"OPTION": this.option,
					"CELL": "20",
					"TEXT": this.inputText
				};

				if (this.brailleKind === "LIBLOUIS") {
					params.PIN = this.language === "japanese" ? "8" : "6";
				}

				let response = await $axios.post(url, params);
				if (response.status === 200) {
					let hexData = response.data.BRAILLE_RESULT;
					this.outputBraille = Braille.toChar(hexData);
				}
			}
		}
	}
</script>
<style>

</style>
