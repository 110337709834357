const Braille = {
    // 점역문자(Hex)를 점역문자(Unicode)로 변환
    toChar(hexData) {
        let result = "";
        hexData?.split(' ').forEach(hexItem => { // hexData는 space(' ')로 분리
            const rtnNo = parseInt(hexItem, 16);
            if (Number.isInteger(rtnNo)) {
                result += String.fromCodePoint(0x2800 + rtnNo);
            }
        });
        
        return result;
    },
    /**
     * 점역문자(Unicode)를 점역문자(Hex)로 변환
     * @param hexData
     * @returns {string}
     */
    toDotcell(hexData) {
        let result = "";
        // 운영서버에서 hexData undefined 에러 발생해서 처리 (2023-04-25, 정태화)
        if (hexData) {
            hexData.split(' ').map(hexItem => { // hexData는 space(' ')로 분리
                // 점역문 hex를 이진 데이타로 변환
                let binaryData = ("00000000" + (parseInt(hexItem, 16)).toString(2)).substr(-8);
                // binary 가공 처리
                let binaryConvertData = binaryData.substring(1, 5) + "0" + binaryData.substring(5, 8);
                // convert binary to hex
                let hexConvertData = ("0" + parseInt(binaryConvertData, 2).toString(16).toUpperCase()).substr(-2);
                result += hexConvertData;
            });
        }
        return result;
    },
    /**
     * 유니코드 문자를 16진수로 변환
     * @param {String} unicodeData
     * @returns {String}
     */
    toHex(unicodeData) {
        const braillePatternBlank = 10240; // Decimal Code
        if (!unicodeData) {
            return '';
        }

        return Array.from(unicodeData).map(char => {
            const codePoint = char.codePointAt(0) - braillePatternBlank;
            return codePoint.toString(16).toUpperCase().padStart(2, '0');
        }).join(' ');
    },
}

export {
    Braille
};

